<template>
    <card icon="eye"
          :headline="$t('visitors.topPages.headline')"
          :description="$t('visitors.topPages.description')">
        <top-table class="top-pages"
                   :table-hover="true"
                   :limit="limit"
                   :items="loadedPages"
                   :title="$t('visitors.topPages.page')"
                   :visits="$t('visitors.topPages.visits')"
        >
            <template #title="{item, block}">
                <div class="title animate" :class="[ block ]">
                    {{item.pageTitle}}
                </div>
                <a class="page-link"
                   :href="link(item)"
                   target="_blank"
                   rel="noopener noreferrer">
                    <span class="page">{{path(item)}}</span>
                </a>
            </template>

            <template #visits="{item}">
                {{item.entrances | numberFormat}}
            </template>
        </top-table>
    </card>
</template>

<script>
  import TopTable from '@/app/website/components/TopTable'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      limit: {
        type: Number,
        default: 5
      }
    },

    data () {
      return {
        maxPages: 3
      }
    },

    computed: {
      ...mapGetters('website', {
        analyticsMostVisitedPages: 'getMostVisitedPages'
      }),
      ...mapGetters('identity', {
        identity: 'getIdentity'
      }),
      ...mapGetters('customer', {
        customer: 'getCustomer'
      }),

      hasLoaded () {
        return this.analyticsMostVisitedPages !== null
      },

      total () {
        return this.loadedPages.length
      },

      loadedPages () {
        if (!this.analyticsMostVisitedPages) return []

        return this.analyticsMostVisitedPages.rows.slice(0, this.limit * this.maxPages).map(page => {
          if (page.pageTitle === '(not set)') {
            page.pageTitle = $t('visitors.topPages.noTitleFallback')
          }
          return page
        })
      }
    },

    methods: {
      link ({ landingPagePath }) {
        return landingPagePath.includes(this.customer.domain) ?
          `http://${landingPagePath}` :
          `http://${this.customer.domain}${landingPagePath}`
      },

      path ({ landingPagePath }) {
        return landingPagePath === '/' ? this.customer.domain : landingPagePath
      }
    },

    components: {
      TopTable
    }
  }
</script>
